// Import the base CSS, if you're using webpack just import them straight.
// Else import them into your base CSS.
import React from "react"

import "../components/foundation.min.css"
import "../components/style.css"

import { useEffect, useState } from "react";

function Timeline() {
  const fixedText = "";
  const whenNotFixed = "";
  const [headerText, setHeaderText] = useState(whenNotFixed);
  
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        if (headerText !== fixedText) {
          setHeaderText(fixedText);
        }
      } else {
        header.classList.remove("sticky");
        if (headerText !== whenNotFixed) {
          setHeaderText(whenNotFixed);
        }
      }
    });
    window.removeEventListener("scroll", scrollCallBack);    
  }, [headerText]);

  return (
    <div>
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell">
            <section className="programme">
              <div className="row margin-top"> 
                <center><h3>Garis Waktu</h3>
                <p>Lorem ipsum dolor sit amet</p>
               
                </center>
                <div className="margin-top-large margin-bottom">
                  <p>Alat Navigasi:</p>
                  <ul>
                    <li><a href="#test">&#10177;&nbsp;··························&nbsp;&nbsp;Reinasans</a></li>
                    <li><a href="#test">&#10177;&nbsp;··························&nbsp;&nbsp;Barok</a></li>
                  </ul>
                </div>
             

                <header className="row titles" id="myHeader">
                  <p>{headerText}</p>
                  <div className="symbol">&nbsp;</div>
                  <div className="title">Kejadian</div>
                  <div className="location">Lokasi</div>
                  <div className="date down"><a href="#test">Tahun</a></div>
                  <div className="years-ago"><a href="#test"><span className="x">x</span> tahun lalu</a></div>
                </header>
                <ul className="row programme-list">
                  <li data-category="Behavioural Attitudes" className="  behavioural">
                    <a href="http://formcontent.org/programme/revocation/" className="group ">
                    <div className="symbol">&#10177;<span className="small-symbol"></span></div>
                      <div className="title">
                        <span className="programme-title">Big Bang (munculnya zat dan energi, atom dan molekul)</span>
                      </div>
                      <div className="location">-</div>
                      <div className="date"></div>
                      <div className="years-ago">13.8 M</div>
                    </a>
                  </li>
                  <li data-category="Encounters Leave Traces" className="  encounters">
                    <a href="http://formcontent.org/programme/dear-friends-of-avant-garde-cinema/" className="group ">
                      <div className="symbol">&#9678;<span className="small-symbol"></span></div>
                      <div className="title">
                        <span className="programme-title">Terbentuknya planet Bumi</span>
                      </div>
                    
                    
                      <div className="location">-</div>
                      <div className="date"></div>
                      <div className="years-ago">4.5 M</div>
                    </a>
                  </li>

                  <li data-category="Behavioural Attitudes" className="  behavioural">
                    <a href="http://formcontent.org/programme/honeymoon-in-paradise/" className="group ">
                      <div className="symbol">&#10697;<span className="small-symbol"></span></div>
                      <div className="title">
                        <span className="programme-title">Kemunculan makhluk hidup di planet bumi</span>
                      </div>
                      <div className="location">-</div>
                      <div className="date"></div>
                      <div className="years-ago">3.8 M</div>
                    </a>
                  </li>

                  <li data-category="Behavioural Attitudes" className="  behavioural">
                    <a href="http://formcontent.org/programme/honeymoon-in-paradise/" className="group ">
                      <div className="symbol">&#10697;<span className="small-symbol"></span></div>
                      <div className="title">
                        <span className="programme-title">Kemunculan makhluk hidup di planet bumi</span>
                      </div>
                      <div className="location">-</div>
                      <div className="date"></div>
                      <div className="years-ago">3.8 M</div>
                    </a>
                  </li>

                 
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;